import React from 'react'
import Layout from "../components/layout";

export default class TermsAndConditions extends React.Component {

    constructor(props){
        super(props)    

        this.state = {
            textData:""
        }
    }

   render() {
       return (
        <Layout>
            <div className="w-full max-w-3xl p-4 mx-auto text-black text-center py-32">
                <p className="text-4xl mx-auto text-left mb-4">Terms and Conditions</p>
                <div className="h-1 mx-auto gradient opacity-25 rounded"></div>
                <div className="text-2xl mx-auto text-left mt-4">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
                    <br></br>
                    <br></br>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   
                    <br></br>
                    <br></br>
                    <b>
                    Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet,
                    </b>
                </div>
            </div>
        </Layout>
       )
   }
}
